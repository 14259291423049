<template>
    <svg
        class="fill-stroke -rotate-90 transition duration-300 hover:text-sky-500 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <polyline points="6 9 12 15 18 9" />
    </svg>
</template>

<script>
export default {
    name: "IconMore",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
