<template>
    <div class="fixed z-50 bg-black/80 w-full h-12">
        <nav-menu></nav-menu>
    </div>
    <div class="min-h-screen bg-white p-4 pt-16">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import NavMenu from "./components/Menu.vue";

export default {
    name: "App",
    components: {
        NavMenu,
    },
    methods: {
        ...mapActions(["getProfile", "loadSettings", "getAll"]),
    },
    mounted() {
        this.getProfile();
        this.loadSettings();
        this.getAll();
    },
};
</script>
