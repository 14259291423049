<template>
    <div class="container my-10 mx-auto">
        <h1 class="text-5xl mb-20 font-extralight">
            Studio<span class="font-bold text-[#ff6000]">Блог</span>
            <span class="text-lg block">эффективные IT решения для бизнеса</span>
        </h1>

        <div class="flex justify-between mt-4">
            <div v-for="blog in blogs" :key="blog" class="border border-slate-100 p-10 rounded">
                <div class="flex justify-between">
                    <div class="text-xs text-slate-400">{{ moment(blog.create_at).format("DD.MM.YY") }}</div>
                    <div class="w-auto block text-sm text-white bg-teal-400 rounded px-2">{{ blog.category_id }}</div>
                </div>
                <h3 class="text-lg font-bold">{{ blog.name }}</h3>
                <div v-html="blog.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "BlogPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["blogs"]),
    },

    methods: {
        ...mapActions(["pickBlog"]),
    },
};
</script>
