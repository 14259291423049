<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-120px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <div class="flex flex-col p-8 pb-40 text-center h-full overflow-auto">
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Редактировать задачу</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="task.name" />
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Статус</label>

                <div class="w-full flex justify-between items-center mb-4 text-sm bg-slate-100 text-slate-400 rounded">
                    <div
                        @click="task.status = 1"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-[#ff6000] !text-white': task.status == 1 }"
                    >
                        <span class="flex items-center">Активна</span>
                    </div>
                    <div
                        @click="task.status = 7"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-teal-500 !text-white': task.status == 7 }"
                    >
                        <span class="flex items-center">Завершена</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Дедлайн</label>
                <input type="datetime-local" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="task.deadline" />
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Оценка времени на задачу (минут)</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="task.estimate" />
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="saveTask(task), closePopup(), pickTask(null)">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "EditTask",

    data() {
        return {
            moment: moment,
            roles: {
                advertiser: "Рекламодатель",
                agency: "Агентство",
                ors: "Рекламная система",
                publisher: "Издатель",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "task"]),
    },

    methods: {
        ...mapActions(["saveTask", "closePopup", "pickTask"]),

        taskTag(role) {
            let roles = new Set(JSON.parse(this.task.roles));
            if (roles.has(role)) {
                roles.delete(role);
            } else {
                roles.add(role);
            }
            this.task.roles = JSON.stringify([...roles]);
        },
    },

    mounted() {},
};
</script>
