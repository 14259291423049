import { createStore } from "vuex";
import API from "../services/api";
import moment from "moment";

import profile from "./modules/profile";
import task from "./modules/task";

export default createStore({
    modules: {
        profile,
        task,
    },

    state: {
        projects: [],
        project: {},
        blogs: [],
        blog: {},

        alert: {},
        s: {
            findme: null,
            label: null,
            account_id: null,
            project_id: null,
            channel_id: null,
            campaign_id: null,
            client_id: null,
            targetolog_id: null,
            status: null,
            month: parseInt(moment().format("MM")),
            start: moment().format("YYYY-MM-01"),
            end: moment().endOf("month").format("YYYY-MM-DD"),
            view: null,
            popup: null,
            clear: null,
            order_name: null,
            order: true,
            request: false, // запрос отправлен = true / иначе показываем анимацию загрузки
            minirequest: false, // запрос отправлен = true / иначе показываем анимацию загрузки
            activeMenu: null,
            mode: null, // тема
        },
    },

    mutations: {
        setAlert: (state, alert) => {
            state.alert = alert;
        },
        setSetting: (state, s) => {
            localStorage.setItem("s", JSON.stringify(s));
            state.s = s;
        },

        setProjects(state, projects) {
            state.projects = projects;
            state.projectsF = projects;
        },
        setProjectsF(state, projects) {
            state.projectsF = projects;
        },
        setProject(state, project) {
            localStorage.setItem("project", JSON.stringify(project));
            state.project = project;
        },

        setBlogs(state, blogs) {
            state.blogs = blogs;
        },
        setBlog(state, blog) {
            state.blog = blog;
        },
    },

    actions: {
        clearAlert(ctx) {
            ctx.commit("setAlert", null);
        },
        request(ctx, status) {
            ctx.state.s.request = status;
            ctx.commit("setSetting", ctx.state.s);
        },
        loadSettings(ctx) {
            let s = JSON.parse(localStorage.getItem("s"));
            if (s) {
                s.minirequest = false;
                ctx.commit("setSetting", s);
            }
        },

        setSettings(ctx, s) {
            if (ctx.state.s.end < ctx.state.s.start) ctx.state.s.end = ctx.state.s.start;
            if (ctx.state.s.findme || ctx.state.s.label) ctx.state.s.clear = true;
            ctx.commit("setSetting", s);
        },
        setPopup(ctx, popup) {
            ctx.state.s.popup = popup;
            ctx.commit("setSetting", ctx.state.s);
        },
        closePopup(ctx) {
            ctx.state.s.popup = false;
            ctx.commit("setSetting", ctx.state.s);
        },

        getAll(ctx) {
            API.GET("api/all").then((res) => {
                ctx.commit("setProjects", res.data.projects);
                ctx.commit("setBlogs", res.data.blogs);
            });
        },
    },

    getters: {
        s(state) {
            return state.s;
        },
        projects(state) {
            return state.projects;
        },
        blogs(state) {
            return state.blogs;
        },
    },
});
