<template>
    <div class="container flex justify-between items-center mx-auto h-12">
        <a @click="$router.push('/')" class="flex justify-between items-center text-xl text-white cursor-pointer">
            <img src="../assets/logo.png" class="h-6 my-3 mr-3" alt="StudioBaraban" />
            <span class="font-extralight">Studio</span>
            <span class="font-bold text-[#ff6000]">Baraban</span>
        </a>

        <ul class="flex justify-between items-center text-center h-12 gap-10">
            <li>
                <a @click="$router.push('/project')" class="items-center text-sm text-white hover:text-[#ff6000] cursor-pointer">Проекты</a>
            </li>
            <li>
                <a @click="$router.push('/work')" class="items-center text-sm text-white hover:text-[#ff6000] cursor-pointer">Как мы работаем</a>
            </li>
            <li>
                <a @click="$router.push('/blog')" class="items-center text-sm text-white hover:text-[#ff6000] cursor-pointer">Блог</a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "BotMenu",

    data() {
        return {};
    },

    computed: {
        ...mapGetters(["profile", "portfolio"]),
    },
};
</script>
