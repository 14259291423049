import Menu from "@/components/Menu";

import IClock from "@/components/icons/clock";
import IClose from "@/components/icons/close";
import IDelete from "@/components/icons/delete";
import IMinus from "@/components/icons/minus";
import IOk from "@/components/icons/ok";
import IPause from "@/components/icons/pause";
import IPlus from "@/components/icons/plus";
import IRight from "@/components/icons/right";
import IStart from "@/components/icons/start";
import IStop from "@/components/icons/stop";

import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconClose from "@/components/icons/IconClose";
import IconLink from "@/components/icons/IconLink";
import IconLoader from "@/components/icons/IconLoader";
import IconRe from "@/components/icons/IconRe";
import IconHome from "@/components/icons/IconHome";
import IconMore from "@/components/icons/IconMore";

import EditTask from "@/components/Task/EditTask";

export default [
    Menu,

    IClock,
    IClose,
    IDelete,
    IMinus,
    IOk,
    IPause,
    IPlus,
    IRight,
    IStart,
    IStop,

    IconPlus,
    IconEdit,
    IconDelete,
    IconClose,
    IconLink,
    IconLoader,
    IconRe,
    IconHome,
    IconMore,
    EditTask,
];
