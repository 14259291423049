<template>
    <div class="container my-10 mx-auto">
        <h1 class="text-5xl mb-20 font-extralight">
            Studio<span class="font-bold text-[#ff6000]">Baraban</span>
            <span class="text-lg block">эффективные IT решения для бизнеса</span>
            <!-- продаж, обучения, финансов  -->
        </h1>

        <div class="flex gap-4">
            <div class="w-1/4 h-96 bg-slate-100 rounded p-16">
                <h2 class="text-3xl font-bold mb-4">CRM</h2>
                <p>Клиенты, продажи, сотрудники</p>
            </div>

            <div class="w-1/4 h-96 bg-slate-100 rounded p-16">
                <h2 class="text-3xl font-bold mb-4">ERP</h2>
                <p>Ресурсы компании, данные, аналитика</p>
            </div>

            <div class="w-1/4 h-96 bg-slate-100 rounded p-16">
                <h2 class="text-3xl font-bold mb-4">EdTech</h2>
                <p>IT технологии в образовании детей и взрослых</p>
            </div>

            <div class="w-1/4 h-96 bg-slate-100 rounded p-16">
                <h2 class="text-3xl font-bold mb-4">FinTech</h2>
                <p>Управление финансами с помощью роботов</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BarabanHome",

    data() {
        return {
            connection: {},
        };
    },

    mounted() {},
};
</script>
