<template>
    <div class="w-full">
        <div class="flex justify-between items-center bg-white p-4">
            <h2 class="text-sm text-slate-500">
                ЗАВЕРШЕННЫЕ СДЕЛКИ <sup>{{ deals.length }}</sup>
            </h2>
        </div>

        <div class="flex justify-between items-center bg-slate-400 text-white rounded p-4 mt-4">
            <div v-for="week in deals" :key="week" class="flex flex-col text-right">
                <p class="text-xs text-slate-200">{{ moment(week.date).format("DD.MM") }}</p>
                <p class="leading-5 text-lg font-bold">{{ week.sum.toLocaleString() }}</p>
                <p v-if="week.percent > 0" class="text-xs text-teal-200">+{{ parseFloat(week.percent).toFixed(2) }}</p>
                <p v-else class="text-xs text-slate-200">{{ week.percent }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ProjectPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "deals", "payments", "profit"]),
    },

    methods: {
        ...mapActions(["setDeal", "setPopup", "closePopup", "getOptions", "findDeals", "changePercentProfit", "addOptionDeal"]),
    },

    mounted() {
        this.getOptions();
    },
};
</script>
