<template>
    <template v-if="!s.request">
        <!-- <div class="flex justify-center items-center gap-20">
            <div class="w-48 h-24 flex flex-col justify-center items-center bg-teal-400 rounded-lg">
                <span class="text-white text-xs">ПРОЕКТЫ</span>
                <span class="text-white text-2xl">{{ total.tasks }}</span>
                <span class="text-white text-xs">заполнены</span>
            </div>
            <div class="w-48 h-24 flex flex-col justify-center items-center bg-pink-400 rounded-lg">
                <span class="text-white text-xs">ПРОЕКТЫ</span>
                <span class="text-white text-2xl">{{ total.tasks }}</span>
                <span class="text-white text-xs">с ошибками</span>
            </div>
        </div> -->

        <!-- <div class="grid grid-cols-5 gap-8">
            <div v-for="p in month.data" :key="p.id" class="bg-white rounded p-4 text-center border border-black">
                <div class="text-slate-500">{{ p.name }}</div>
                <div class="text-[#ff6000] font-bold text-xl my-4">{{ parseInt((p.sec / 3600) * 3000).toLocaleString() }} р</div>
            </div>
        </div> -->

        <div class="grid grid-cols-5 gap-8">
            <div v-for="p in total" :key="p.id" class="bg-white rounded p-4 text-center border border-black">
                <div class="text-slate-500">{{ p.name }}</div>
                <div class="text-[#ff6000] font-bold text-xl my-4">{{ parseInt((p.sec / 3600) * 3000).toLocaleString() }} р</div>

                <!-- <div class="text-slate-500 mb-4">{{ parseInt(p.sec / 3600) }} ч. {{ parseInt((p.sec % 3600) / 60) }} м.</div> -->

                <template v-if="p.items && p.items.length > 0">
                    <div v-for="item in p.items" :key="item.id" class="text-center text-xs p-1 mb-px bg-slate-100">
                        {{ item.name }}
                    </div>
                </template>
            </div>
        </div>

        <div class="flex justify-between items-center my-4 gap-4">
            <div class="shrink-0 text-sm text-slate-500 uppercase">
                ЗАДАЧИ <span class="count">{{ tasks?.length }}</span>
            </div>

            <!-- <input type="text" class="input" placeholder="Поиск проекта" v-model="s.findme" @input="filterTasks()" /> -->

            <!-- <select v-model="s.status" class="bg-white h-9 px-3 py-2 rounded text-sm text-sky-800" @change="filterTasks()">
                <option :selected="s.status == null" :value="null">ВСЕ</option>
                <option :selected="s.status == 7" :value="7">АКТИВНЫ</option>
                <option :selected="s.status == 0" :value="0">ВЫКЛЮЧЕНЫ</option>
            </select> -->

            <div v-if="s.clear" class="text-xs text-slate-400 cursor-pointer hover:text-cyan-600" @click="clearSettings()">ОЧИСТИТЬ</div>

            <div @click="createTask()" class="btn btn-green gap-2">
                <span class="text-white my-2"><IPlus :size="5" /></span>
                <span class="text-white text-xs">СОЗДАТЬ</span>
            </div>
        </div>

        <div class="bg-white rounded">
            <div class="grid grid-cols-12 items-center text-xs text-slate-400 gap-4 p-4 border-b border-slate-200 uppercase">
                <div>Статус</div>
                <div>ПРОЕКТ</div>
                <div class="col-span-5">НАЗВАНИЕ</div>
                <div>Дедлайн</div>
                <div>Оценка</div>
                <div>Время</div>

                <div>Таймер</div>

                <div></div>
            </div>

            <template v-for="item in tasks" :key="item.id">
                <div
                    class="grid grid-cols-12 items-center gap-4 p-4 border-b border-slate-200 text-sm hover:bg-cyan-50/50"
                    :class="{ '!bg-teal-100 !text-teal-700': item.status == 7, '!text-[#ff6000]': item.status == 2 }"
                >
                    <div>
                        <div v-if="item.status == 1"><IRight :size="6" /></div>
                        <div v-if="item.status == 2"><IClock :size="6" /></div>
                        <div v-if="item.status == 7"><IOk :size="6" /></div>
                    </div>

                    <div>{{ item.project_id }}</div>

                    <div @click="pickTask(item), setPopup('editTask')" class="col-span-5 cursor-pointer">
                        <sup>{{ item.id }}</sup> {{ item.name }}
                    </div>

                    <div>
                        <div v-if="item.deadline">{{ moment(item.deadline).format("DD.MM.YYYY") }}</div>
                        <div v-else>-</div>
                    </div>

                    <div>{{ item.estimate }}</div>

                    <div class="tabular-nums" v-if="activeTime?.task_id == item.id && item.status == 2">
                        {{
                            moment
                                .unix(item.sec + timer)
                                .tz("Europe/Moscow")
                                .format("HH:mm:ss")
                        }}
                    </div>
                    <div class="tabular-nums" v-else>
                        {{
                            moment
                                .unix(item.sec - 3600 * 3)
                                .tz("Europe/Moscow")
                                .format("HH:mm:ss")
                        }}
                    </div>

                    <div>
                        <div v-if="item.active">{{ item.active.start }} {{ moment().format("HH:mm:ss") }}</div>
                        <div v-else>-</div>
                    </div>

                    <div class="flex justify-center gap-2 relative group cursor-pointer">
                        <div v-if="item.status == 1" @click="startTask({ id: item.id, timer: timer + 3600 * 3 })"><IStart /></div>
                        <div v-if="item.status == 2" @click="stopTask({ id: item.id, timer: timer + 3600 * 3 })"><IPause /></div>
                    </div>
                </div>
            </template>
        </div>

        <template v-if="s.popup == 'editTask' && task?.id"><EditTask /></template>
        <template v-if="s.popup == 'viewTask' && task?.id"><EditTask /></template>
        <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
    </template>

    <div v-else class="flex justify-center items-center h-screen text-cyan-400"><IconLoader :size="30" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import tz from "moment-timezone";

export default {
    name: "TasksPage",

    data() {
        return {
            moment: moment,
            tz: tz,
            timer: 0,
            projects: {
                1: "Маркет-платформа",
                2: "ОРД",
                3: "Пользователи",
                4: "Кабинет таргетолога",
                5: "Статистика",
                6: "ERID ",
                7: "Логирование",
                8: "Финансовый отдел",
            },
        };
    },

    watch: {
        activeTime() {
            console.log("watch activeTime", this.activeTime);
            this.timer = moment.tz("Europe/Moscow").unix() - moment.tz(this.activeTime?.start, "Europe/Moscow").unix() - 3600 * 3;
        },

        timer: {
            handler() {
                setTimeout(
                    () => (this.timer = moment.tz("Europe/Moscow").unix() - moment.tz(this.activeTime?.start, "Europe/Moscow").unix() - 3600 * 3),
                    1000
                );
            },
            immediate: true, // This ensures the watcher is triggered upon creation
        },
    },

    computed: {
        ...mapGetters(["s", "profile", "tasks", "task", "activeTime"]),

        total() {
            if (!this.tasks || this.tasks.length < 1) return null;

            let data = this.tasks.reduce((result, item) => {
                const outerKey = item.project_id;
                if (!result[outerKey]) {
                    result[outerKey] = { id: item.project_id, name: "", items: [], sec: 0 };
                    result[outerKey].name = this.projects[item.project_id];
                }
                result[outerKey].items.push(item);
                result[outerKey].sec += item.sec;
                return result;
            }, {});

            return data;
        },

        month() {
            if (!this.tasks || this.tasks.length < 1) return null;

            let total = 0;
            let data = [];
            this.tasks.forEach((task) => {
                let project = { id: task.project_id, name: task.name, sec: 0 };
                task.times.forEach((time) => {
                    if (moment(time.end).format("YYYY-MM-DD") >= "2023-11-01" && project.id != 100) {
                        project.sec += time.sec;
                        total += time.sec;
                    }
                });
                if (project.sec > 0) data.push(project);
            });

            return { data: data, total: total };
        },
    },

    methods: {
        ...mapActions([
            "getTasks",
            "setPopup",
            "closePopup",
            "setSettings",
            "clearSettings",
            "createTask",
            "filterTasks",
            "editTask",
            "pickTask",
            "startTask",
            "stopTask",
        ]),
    },

    mounted() {
        this.getTasks();

        // if (this.activeTime) this.timer = moment.tz("Europe/Moscow").unix() - moment.tz(this.activeTime?.start, "Europe/Moscow").unix();
    },
};
</script>
