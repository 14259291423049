import moment from "moment";
import API from "../../services/api";
import router from "../../services/router";

export default {
    state: {
        tasks: [],
        tasksF: [],
        task: {},

        activeTime: {},
    },

    mutations: {
        setTasks(state, tasks) {
            state.tasks = tasks;
            state.tasksF = tasks;
        },
        setTasksF(state, tasks) {
            state.tasksF = tasks;
        },
        setTask(state, task) {
            localStorage.setItem("task", JSON.stringify(task));
            state.task = task;
        },

        setActiveTime(state, activeTime) {
            state.activeTime = activeTime;
        },
    },

    actions: {
        getTasks(ctx) {
            if (!ctx.state.tasks || ctx.state.tasks?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("task/all").then((res) => {
                    console.log("getTasks", res.data);
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setTasks", res.data.tasks);
                    ctx.commit("setActiveTime", res.data.activeTime);
                    ctx.dispatch("request", false);
                });
            }
        },

        load(ctx) {
            if (!ctx.state.project || isNaN(parseInt(ctx.state.project.id)) || parseInt(ctx.state.project.id) < 1) {
                let project = JSON.parse(localStorage.getItem("project"));
                if (project && project.id > 0) ctx.commit("setTask", project);
            }
            if (!ctx.state.client || isNaN(parseInt(ctx.state.client.id)) || parseInt(ctx.state.client.id) < 1) {
                let client = JSON.parse(localStorage.getItem("client"));
                if (client && client.id > 0) ctx.commit("setClient", client);
            }
        },

        // проверить что клиент выбран
        isTask(ctx) {
            console.log("isTask");
            if (!ctx.state.project || isNaN(parseInt(ctx.state.project.id)) || parseInt(ctx.state.project.id) < 1) {
                console.log("isTask скачать");
                ctx.dispatch("load");
                if (!ctx.state.project) {
                    router.push("/projects");
                    ctx.dispatch("setActiveMemu", "/projects");
                    return;
                }
                if (isNaN(parseInt(ctx.state.project.id))) {
                    router.push("/projects");
                    ctx.dispatch("setActiveMemu", "/projects");
                    return;
                }
                if (parseInt(ctx.state.project.id) < 1) {
                    router.push("/projects");
                    ctx.dispatch("setActiveMemu", "/projects");
                    return;
                }
                ctx.dispatch("pickTask", ctx.state.project.id);
            }
        },

        pickTask(ctx, task) {
            ctx.commit("setTask", task);
        },

        createTask(ctx) {
            // if (!ctx.state.project.id) return null;

            let formData = new FormData();
            // formData.append("project_id", ctx.state.project.id);
            API.POST("task/create", formData).then((res) => {
                console.log("createTask", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTasks", res.data.tasks);
                ctx.commit("setTask", res.data.task);
                ctx.dispatch("setPopup", "editTask");
            });
        },

        saveTask(ctx, data) {
            if (!data.id) return null;

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("category_id", data.category_id);
            formData.append("user_id", data.user_id);
            formData.append("name", data.name);
            formData.append("info", data.info);
            formData.append("label", data.label);
            formData.append("estimate", data.estimate);
            formData.append("deadline", data.deadline);

            API.POST("task/update", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTasks", res.data.tasks);
                ctx.commit("setTask", res.data.task);
            });
        },

        startTask(ctx, { id, timer }) {
            if (!id) return null;
            console.log("sec", timer);

            const start = moment().tz("Europe/Moscow").format("YYYY-MM-DD HH:mm:ss");
            let activeTime = { task_id: id, start: start };
            ctx.commit("setActiveTime", activeTime);

            ctx.state.tasks.forEach((task, i) => {
                if (task.id == id) ctx.state.tasks[i].status = 2;
                if (task.status == 2 && task.id != id) {
                    ctx.state.tasks[i].status = 1;
                    ctx.state.tasks[i].sec = task.sec + timer;
                }
            });
            ctx.commit("setTasks", ctx.state.tasks);

            let formData = new FormData();
            formData.append("id", id);
            formData.append("start", start);

            API.POST("task/start", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTasks", res.data.tasks);
                ctx.commit("setActiveTime", res.data.activeTime);
            });
        },

        stopTask(ctx, { id, timer }) {
            if (!id) return null;

            ctx.commit("setActiveTime", {});
            const stop = moment().tz("Europe/Moscow").format("YYYY-MM-DD HH:mm:ss");

            ctx.state.tasks.forEach((task, i) => {
                if (task.status == 2 && task.id != id) {
                    ctx.state.tasks[i].status = 1;
                    ctx.state.tasks[i].sec = task.sec + timer;
                }
            });
            ctx.commit("setTasks", ctx.state.tasks);

            let formData = new FormData();
            formData.append("id", id);
            formData.append("stop", stop);

            API.POST("task/stop", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTasks", res.data.tasks);
                ctx.commit("setActiveTime", res.data.activeTime);
            });
        },
    },

    getters: {
        tasks(state) {
            return state.tasksF;
        },
        task(state) {
            return state.task;
        },

        activeTime(state) {
            return state.activeTime;
        },
    },
};
