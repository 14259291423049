import { createRouter, createWebHistory } from "vue-router";

import MainPage from "@/Main/Main";

import ProjectsPage from "@/pages/Projects";
import ProjectPage from "@/pages/Project";

import BlogsPage from "@/pages/Blogs";
import BlogPage from "@/pages/Blog";

import TasksPage from "@/Task/Tasks";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/project",
        name: "projects",
        meta: { title: "Проекты" },
        component: ProjectsPage,
    },
    {
        path: "/project/:id",
        name: "project",
        component: ProjectPage,
    },
    {
        path: "/blog",
        name: "blogs",
        meta: { title: "Блог" },
        component: BlogsPage,
    },
    {
        path: "/blog/:id",
        name: "blog",
        component: BlogPage,
    },
    {
        path: "/tasks",
        name: "Tasks",
        meta: { title: "Задачи" },
        component: TasksPage,
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach(async (to) => {
//     document.title = to.meta.title || "studio.baraban.io";

//     if (
//         // make sure the user is authenticated
//         !authService.isLogged() &&
//         // Avoid an infinite redirect
//         to.name !== "Login" &&
//         to.name !== "Main"
//     ) {
//         // redirect the user to the login page
//         // this.$router.push('/')
//         return { name: "Login" };
//     }
//     window.scrollTo(0, 0);
// });

export default router;
