<template>
    <div class="container my-10 mx-auto">
        <h1 class="text-5xl mb-20 font-extralight">
            Studio<span class="font-bold text-[#ff6000]">Проекты</span>
            <span class="text-lg block">эффективные IT решения для бизнеса</span>
        </h1>

        <div class="grid grid-cols-3 justify-between gap-4 mt-4">
            <template v-for="project in projects" :key="project">
                <div
                    :class="{ '!row-span-2 h-full': [6].includes(project.id), '!col-span-2': project.size == 2 }"
                    class="bg-slate-200 rounded-lg p-8 h-64"
                >
                    <div class="flex justify-between">
                        <div class="text-xs text-slate-500">{{ project.create_at }}</div>
                        <div
                            class="w-auto block text-sm text-white rounded px-2"
                            :class="{
                                'bg-[#ff6000]': project.category == 'CRM',
                                'bg-pink-500': project.category == 'ERP',
                                'bg-sky-500': project.category == 'EdTech',
                                'bg-teal-500': project.category == 'FinTech',
                            }"
                        >
                            {{ project.category }}
                        </div>
                    </div>
                    <h3 class="text-lg font-bold">{{ project.name }}</h3>
                    <div class="text-xs text-slate-500">{{ project.url }}</div>
                    <p class="text-sm text-slate-500">{{ project.text }}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ProjectsPage",

    data() {
        return {
            moment: moment,
            projects: [
                {
                    id: 17,
                    name: "MOEX",
                    text: "Управление торговыми стратегиями на срочном рынке Московской биржи",
                    url: "url скрыт",
                    create_at: "июнь 2023",
                    category: "FinTech",
                    size: 2,
                },
                {
                    id: 15,
                    name: "Subscribe",
                    text: "Сервис подписок с рекуррентными платежами",
                    url: "https://subscribe.igoevent.com/",
                    create_at: "май 2023",
                    category: "CRM",
                    size: 1,
                },
                { id: 14, name: "Market Publisher", text: "", url: "url скрыт", create_at: "апрель 2023", category: "CRM", size: 1 },
                { id: 13, name: "Vk Stat", text: "", url: "url скрыт", create_at: "март 2023", category: "ERP", size: 1 },

                {
                    id: 16,
                    name: "CryptoBot",
                    text: "Алгоритмическая торговля криптоактивами на бирже Binance",
                    url: "url скрыт",
                    create_at: "февраль 2023",
                    category: "FinTech",
                    size: 1,
                },
                { id: 12, name: "Dynamic Shifts", text: "", url: "https://dynamicshift.ru/", create_at: "январь 2023", category: "ERP", size: 1 },
                { id: 11, name: "TahoCRM", text: "", url: "https://tahocrm.ru/", create_at: "август 2022", category: "ERP", size: 2 },
                { id: 10, name: "BotTeachMe", text: "", url: "https://botteach.me/", create_at: "март 2022", category: "EdTech", size: 2 },
                { id: 9, name: "SunCapital", text: "", url: "url скрыт", create_at: "февраль 2022", category: "FinTech", size: 1 },
                { id: 8, name: "Money", text: "", url: "url скрыт", create_at: "март 2021", category: "FinTech", size: 1 },
                { id: 7, name: "Vlavashe", text: "", url: "https://vlavashe.ru/", create_at: "июнь 2020", category: "CRM", size: 1 },
                { id: 6, name: "CashResto", text: "", url: "https://cashresto.ru/", create_at: "март 2017", category: "EdTech", size: 1 },
                { id: 5, name: "iGoEvent", text: "", url: "https://igoevent.com/", create_at: "июль 2018", category: "CRM", size: 1 },
                { id: 4, name: "TYMind", text: "", url: "https://tymind.ru/", create_at: "февраль 2017", category: "EdTech", size: 1 },
                { id: 3, name: "DentalCRM", text: "", url: "http://dentalcrm.ru/", create_at: "сентябрь 2015", category: "CRM", size: 2 },
                { id: 2, name: "Intellect Drive", text: "", url: "проект закрыт", create_at: "февраль 2014", category: "CRM", size: 1 },
                // { id: 1, name: "HumanSoft", text: "", url: "url скрыт", create_at: "май 2012", category: "CRM", size: 1 },
            ],
        };
    },

    // https://www.youtube.com/watch?v=30XmWMfFJew

    computed: {
        ...mapGetters([""]),
    },

    methods: {
        ...mapActions(["pickProject"]),
    },
};
</script>
